import React, { useState, useEffect } from "react";
import { useNavigate, Link, json } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import Tekst from "../Tekstenbieb";
import Nav from "../../navigation/Nav";
import Menu from "../../navigation/Menu";
import DateTime from "react-datetime";
import InhoudsOpgave from "../InhoudsOpgave";
import Instruments from "../Instruments";
import Aansluitgegevens from "../Aansluitgegevens";
import Normen from "../Normen";
import NormenGetoetst from "../NormenGetoetst";
import "moment/locale/nl";
import { AiOutlineLeft } from "react-icons/ai";
import "../../../scss/reports/select-inspectie.scss";
import {
  get_inspecties,
  insert_rapportage,
  get_user,
} from "../../../functions/api";
function Inspectie() {
  // permissions
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const navigate = useNavigate();

  const [data, setData] = useState();
  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(true);

  let [inspectionId, setInspectionId] = useState("");
  let [customerName, setCustomerName] = useState("");
  let [scope, setScope] = useState("");
  let [objectName, setObjectName] = useState("");
  let [inspectionDate, setInspectionDate] = useState();
  let [sortId, setSortId] = useState("");
  let [mediaUrl, setMediaUrl] = useState("");

  // Creating all the necessary states

  let [inhoudsopgave, setInhoudsOpgave] = useState([]);
  let [subtitel, setSubtitel] = useState("");
  let [instellingen, setInstellingen] = useState([]);
  let [inleidingAlgemeen, setInleidingAlgemeen] = useState("");
  let [inleidingKwaliteitswaarborg, setInleidingKwaliteitswaarborg] =
    useState("");
  let [inleidingHerstelgebreken, setInleidingHerstelgebreken] = useState("");

  let [installatieverantwoordelijke, setInstallatieverantwoordelijke] =
    useState([]);
  let [meetinstrumenten, setMeetinstrumenten] = useState([]);

  let [kenmerkAlgemeen, setKenmerkalgemeen] = useState("");
  let [frequentiedraairichting] = useState("");
  let [objectfuncties, setObjectfuncties] = useState([]);
  let [aansluitgegevens, setAansluitgegevens] = useState("");
  let [aansluitgegevensArr, setAansluitgegevensArr] = useState([]);
  let [aansluitgegevenid, setAansluitgegevenid] = useState();

  let [omvanginspectie, setOmvanginspectie] = useState("");
  let [nietgeinspecteerd, setNietgeinspecteerd] = useState("");

  let [redeninspectie, setRedeninspectie] = useState("");
  let [inspectieuitgevoerdvolgens] = useState("");
  let [deelektronischeinstallatie] = useState("");
  let [beoordelingscriteria, setBeoordelingscriteria] = useState("");
  let [uitgangspuntenArr, setUitgangspuntenArr] = useState([]);
  let [uitgangspuntenArr2, setUitgangspuntenArr2] = useState([]);
  let [normid, setNormid] = useState([]);
  let [normid2, setNormid2] = useState([]);

  let [bedrijfsomstandigheden, setBedrijfsomstandigheden] = useState("");
  let [wederzijdsebeinvloeding] = useState("");
  let [uitwendigebeinvloeding] = useState("");
  let [automatischeuitschakeling] = useState("");

  let [volgendeinspectie, setVolgendeInspectie] = useState("");
  let [leeftijd, setLeeftijd] = useState("");
  let [kwaliteit, setKwaliteit] = useState("");
  let [omgevingsomstandigheden, setOmgevingsomstandigheden] = useState("");
  let [personen, setPersonen] = useState("");
  let [matevantoezicht, setMatevantoezicht] = useState("");
  let [datum, setDatum] = useState("");

  let [beoordeling, setBeoordeling] = useState("");
  let [toelichting, setToelichting] = useState("");

  let [totaalPunten, setTotaalPunten] = useState("");

  const [extraFields, setExtraFields] = useState();
  const [callBijlagen, setCallBijlagen] = useState(false);
  const installatieLeeftijd = [
    { value: 1, label: "Jonger dan 10 jaar" },
    { value: 2, label: "Ouder dan 10 jaar" },
    { value: 3, label: "Ouder dan 20 jaar" },
    { value: 4, label: "Ouder dan 30 jaar" },
  ];

  const installatieKwaliteit = [
    {
      value: 1,
      label:
        "Is aanzienelijk beter dan de minimale kwaliteit zoals is vastgesteld in de jongste elektrotechnische normen",
    },
    { value: 2, label: "Voldoet aan de jongste elektrotechnische vormen" },
    {
      value: 3,
      label:
        "Voldoet aan de normen bij de aanleg van toepassing waren + extra veiligheidsvoorzieningen",
    },
    {
      value: 4,
      label: "Voldoet aan de normen die bij de aanleg van toepassing waren",
    },
    {
      value: 5,
      label:
        "Levert het vermoeden of heeft feitelijk aan dat de elektrische installatie niet aan de normen voldoet, er zijn echter geen gevaarlijke situaties aanwezig",
    },
  ];

  const omgevingsOmstandigheden = [
    {
      value: 1,
      label:
        "Schoon en droog; geen brandgevaar t.g.v. stof; geen corrosie- en explosiegevaar; is vrij van transportmiddelen of zware materialen",
    },
    {
      value: 2,
      label:
        "Is niet schoon en droog; bevat explosieve of corrosieve gassen; levert explosiegevaar ten gevolge van stof op; houdt het gebruik van transportmiddelen of zware materialen in",
    },
    {
      value: 3,
      label:
        "Zwaar industrieel met voortdurend gevaar voor veiligheid: vocht; brandbaar materiaal; stof of corrosieve of explosieve gassen of dampen of stof; of houdt gebruik van zware transportmiddelen in",
    },
  ];

  const installatiePersonen = [
    {
      value: 1,
      label:
        "Uitsluitend ervaren elektrotechnische opgeleiden personen of personen zelfstandig kunnen oordelen over veilig werken",
    },
    {
      value: 2,
      label:
        "Niet specifiek elektrotechnische opgeleid personeel, maar wel opgeleid zijn aandacht te geven aan gevaren door werken met elektriciteit",
    },
    { value: 3, label: "Leken" },
    { value: 4, label: "Leerlingen, cursisten, studenten, practisanten" },
  ];

  const regelmaat = [
    { value: 1, label: "Regelmatig" },
    { value: 2, label: "Sporadisch" },
  ];

  const zichtbaarMaken = [
    { value: 1, label: "Grafiek" },
    { value: 2, label: "Sios logo" },
  ];

  const zichtbaarMaken2 = [{ value: 1, label: "Installatieverantwoordelijke" }];

  const SetReportInfo = (e) => {
    if (!e || e === undefined) {
      return;
    }

    data.map(() => {
      let index = data.filter((obj) => e.value === obj.id);
      let date = index[0].datumstart;
      return (
        setInspectionId(index[0].id),
        setCustomerName(index[0].klant.klantnaam),
        setObjectName(index[0].object.naam),
        setInspectionDate(date),
        setSortId(index[0].soort.soortid),
        setMediaUrl(
          index[0].object.mediaurl,
          setScope(index[0].soort.soortnaam)
        )
      );
    });
  };

  const calculatePunten = (object_values) => {
    let totaal = 0;

    if (parseInt(object_values.personen) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.personen) === 2) {
      totaal = totaal + 5;
    }
    if (parseInt(object_values.personen) === 3) {
      totaal = totaal + 8;
    }
    if (parseInt(object_values.personen) === 4) {
      totaal = totaal + 10;
    }

    if (parseInt(object_values.matevantoezicht) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.matevantoezicht) === 2) {
      totaal = totaal + 10;
    }

    if (parseInt(object_values.omgeving) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.omgeving) === 2) {
      totaal = totaal + 10;
    }
    if (parseInt(object_values.omgeving) === 3) {
      totaal = totaal + 20;
    }

    if (parseInt(object_values.kwaliteit) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.kwaliteit) === 2) {
      totaal = totaal + 2;
    }
    if (parseInt(object_values.kwaliteit) === 3) {
      totaal = totaal + 4;
    }
    if (parseInt(object_values.kwaliteit) === 4) {
      totaal = totaal + 7;
    }
    if (parseInt(object_values.kwaliteit) === 5) {
      totaal = totaal + 15;
    }

    if (parseInt(object_values.leeftijd) === 1) {
      totaal = totaal + 0;
    }
    if (parseInt(object_values.leeftijd) === 2) {
      totaal = totaal + 5;
    }
    if (parseInt(object_values.leeftijd) === 3) {
      totaal = totaal + 8;
    }
    if (parseInt(object_values.leeftijd) === 4) {
      totaal = totaal + 10;
    }

    return setTotaalPunten(totaal);
  };

  const insertReport = async () => {
    let bijlagenLijst = [];
    if (selectedExtraBijlagen.length > 0) {
      selectedExtraBijlagen.map((objSelectedBijlage) => {
        bijlagenLijst.push({
          titel: objSelectedBijlage.label,
          pdf: objSelectedBijlage.value,
        });
      });
    }
    const object = {
      rapportid: "s-s",
      inspectie_object: {
        inspectieid: inspectionId,
        objectfoto: mediaUrl,
      },
      rapportnummer: 0,
      inhoudsopgave: inhoudsopgave,
      subtitel: subtitel,
      inleiding: {
        algemeen: inleidingAlgemeen,
        kwaliteitswaarborg: inleidingKwaliteitswaarborg,
        herstelgebreken: inleidingHerstelgebreken,
      },
      algemenegegevens: {
        installatieverantwoordelijke:
          installatieverantwoordelijke !== undefined
            ? Array.isArray(installatieverantwoordelijke)
              ? installatieverantwoordelijke[0] !== undefined
                ? "1"
                : "0"
              : "0"
            : "0",
        meetinstrumenten: meetinstrumenten,
      },
      kenmerken: {
        algemeen: kenmerkAlgemeen,
        frequentie: false,
        draairichting: false,
        frequentiedraairichting: frequentiedraairichting,
        objectfuncties: objectfuncties,
        aansluitgegevens: aansluitgegevens,
        aansluitgegevens_array: aansluitgegevensArr,
      },
      inspectie: {
        omvanginspectie: omvanginspectie,
        nietgeinspecteerd: nietgeinspecteerd,
      },
      uitgangspunten: {
        redeninspectie: redeninspectie,
        inspectieuitgevoerdvolgens: inspectieuitgevoerdvolgens,
        deelektronischeinstallatie: deelektronischeinstallatie,
        beoordelingscriteria: beoordelingscriteria,
        uitgangspunten_array: uitgangspuntenArr,
        uitgangspunten_array_2: uitgangspuntenArr2,
      },
      inspectiemethode: {
        bedrijfsomstandigheden: bedrijfsomstandigheden,
        wederzijdsebeinvloeding: wederzijdsebeinvloeding,
        uitwendigebeinvloeding: uitwendigebeinvloeding,
        automatischeuitschakeling: automatischeuitschakeling,
      },
      volgendeinspectie: {
        volgendeinspectie: volgendeinspectie,
        leeftijd: leeftijd,
        kwaliteit: kwaliteit,
        omgevingsomstandigheden: omgevingsomstandigheden,
        personen: personen,
        matevantoezicht: matevantoezicht,
        datum: datum.toString(),
      },
      beoordeling: {
        eindbeoordeling: beoordeling,
        toelichting: toelichting,
      },
      rapport_offline: {
        klantnaam: customerName,
        objectnaam: objectName,
        soortinspectieid: sortId,
        datumstart: inspectionDate.toString(),
      },
      instellingen: instellingen,
      extraBijlagen: bijlagenLijst.length > 0 ? bijlagenLijst : null,
    };
    const response = await insert_rapportage(JSON.stringify(object));

    const jsonResponse = await response.json();
    window.open(jsonResponse.link);
    return navigate("/reports");
    //
  };
  const [user, setUser] = useState();
  useEffect(() => {
    get_user()
      .then((res) => res.json())
      .then((data) => {
        setUser(data.message);
      });
    get_inspecties()
      .then((report) => {
        if (report.ok) {
          return report.json();
        }
        throw report;
      })
      .then((data) => {
        setData(data);
        if (data.length > 0) {
          const options = data.map((object) => {
            return {
              value: object.id,
              label: `${object.klant.klantnaam} | ${object.locatie[0]?.adres} ${object.locatie[0]?.huisnummer}, ${object.locatie[0]?.plaats} - ${object.object.objectnummer}`,
            };
          });
          setOptions(options);
        } else {
          Swal.fire({
            title: "Geen inspecties gevonden!",
            text: "Maak uw eerste inspectie in de app!",
            imageUrl: "/img/inspectie.png",
            showConfirmButton: true,
          }).then(() => {
            navigate("/reports");
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      })
      .finally(() => {
        if (permissions.createPermissions.createRapportage === "0") {
          Swal.fire({
            title: "Geen toegang!",
            text: "U wordt terug gestuurd naar de vorige pagina.",
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            navigate("/reports");
          });
        }
        setLoading(false);
      });
  }, []);

  async function Scrolling(e) {
    let b = await e;
    SetReportInfo(b);
    document.getElementById("scrollToMe").scrollIntoView();
    return;
  }
  inspectionDate = new Date(inspectionDate).toLocaleString("nl-NL", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  const [extraBijlagen, setExtraBijlagen] = useState([]);
  const [extraBijlagenPdf, setExtraBijlagenPdf] = useState([]);
  const [selectedExtraBijlagen, setSelectedExtraBijlagen] = useState([]);
  async function handlePdfUpload(titel, file) {
    if (titel && file) {
      // Set new state for extraBijlagen

      const formData = new FormData();
      formData.append("pdf", file);
      const response = await fetch(
        "https://api.ezinspect.nl/api/v2/UploadPDF",
        {
          method: "POST",
          body: formData,
        }
      );

      const jsonResponse = await response.json();

      try {
        var newObjExtraBijlagen = {
          label: titel,
          value: jsonResponse.pdf,
        };

        setExtraBijlagen([...extraBijlagen, newObjExtraBijlagen]);

        setSelectedExtraBijlagen([
          ...selectedExtraBijlagen,
          newObjExtraBijlagen,
        ]);
      } catch (error) {
        alert(error);
      }

      // Set new state for extraBijlagenPdf
      setExtraBijlagenPdf([
        ...extraBijlagenPdf,
        {
          titel: titel,
          pdf: jsonResponse.pdf,
        },
      ]);
    }
  }

  const BijlageModal = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success mx-2",
        cancelButton: "btn btn-danger mx-2",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Voeg hier een extra bijlage toe.",
        showCancelButton: true,
        html: `<div style="display: grid; width: 80%; margin: auto;">
        <label>Titel</label>
        <input type="text" style="margin-top: 8px;" id="swal-input1" class="swal2-input" />
        <label>PDF</label>
        <input style="margin-left: 80px; margin-top: 8px;" type="file" accept="application/pdf" id="swal-input2" class="swal2-input" />
        </div>`,
        confirmButtonText: "Ja, voeg toe!",
        cancelButtonText: "Nee, Annuleer!",
        reverseButtons: true,
        preConfirm: () => {
          const titleInput = document.getElementById("swal-input1").value;
          const pdfInput = document.getElementById("swal-input2").files[0];
          if (titleInput && pdfInput) {
            handlePdfUpload(titleInput, pdfInput);
          } else {
            swalWithBootstrapButtons.fire({
              title: "Oeps!",
              text: "U heeft niet alle velden ingevuld.",
              icon: "error",
              confirmButtonText: "Ok!",
            });
          }
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            "Toegevoegd!",
            "Extra bijlage is toegevoegd."
          );
        }
      });
  };

  if (loading === true) {
    return (
      <div className="select-report-container-main">
        <Nav page="Rapportages" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="select-inspectie">
            <h1 className="inspectie-header">Even geduld ..</h1>
            <br />
            <div className="inspectie-select">
              <Select placeholder="Inspecties ophalen..." isDisabled={true} />
            </div>
            <hr className="hr" />
          </div>
        </div>
      </div>
    );
  }
  if (loading === false) {
    return (
      <div className="select-report-container-main">
        <Nav page="Rapportages" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="select-inspectie">
            <Link
              className="fixed bottom-0 left-0 ml-4 mb-4"
              to="/reports"
              style={{
                textDecoration: "none",
                color: "#757575",
                width: "85px",
              }}
            >
              <p className="text-base flex flex-row">
                <AiOutlineLeft className="mt-1" />
                terug
              </p>
            </Link>
            <h1 className="inspectie-header">Rapportage aanmaken</h1>
            <br />
            <div className="inspectie-select">
              <Select
                placeholder="&#x1F50D;&#xFE0E;  Kies Inspectie"
                options={options}
                className="basic-single"
                classNamePvalueix="select"
                onChange={(e) => {
                  Scrolling(e);
                }}
              />
            </div>
            <hr className="hr" />
            <div
              style={{
                display:
                  !inspectionId && inspectionId === "" ? "none" : "contents",
              }}
            >
              <div id="scrollToMe"></div>
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">Voorblad</h1>
              </div>
              <div className="selected-inspectie-row">
                <div className="selected-inspectie-info">
                  <div>
                    <p className="inspectie-text" style={{ color: "#060a24" }}>
                      {inspectionId && inspectionId !== ""
                        ? "Inspectie: " + inspectionId
                        : ""}
                    </p>
                    <p className="inspectie-text">
                      {customerName && customerName !== "" ? customerName : ""}
                    </p>
                    <p className="inspectie-text">
                      {objectName && objectName !== "" ? objectName : ""}
                    </p>
                    <p className="inspectie-text">
                      {scope && scope !== "" ? scope : ""}
                    </p>
                    <p className="inspectie-text">
                      {inspectionDate && inspectionDate !== undefined
                        ? inspectionDate
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="selected-inspectie-info-left">
                  <label className="label-inspectie">Object Foto:</label>
                  <br />
                  <img alt="object-foto" src={mediaUrl} />
                </div>
              </div>
              <hr className="hr1" />
              <div className="rapportage-input-col">
                <div className="rapportage-input-left">
                  <label className="label-inspectie">Bijlage(n)</label>
                  <InhoudsOpgave edit={false} toParent={setInhoudsOpgave} />
                  <label
                    style={{ paddingTop: "2px" }}
                    className="label-inspectie"
                  >
                    Extra Bijlage(n)
                  </label>
                  <br />
                  <div style={{ width: "100%", display: "flex" }}>
                    <button
                      className="btn btn-primary"
                      style={{
                        fontSize: "15px",
                        width: "280px",
                        height: "45.5px",
                        marginTop: "1px",
                      }}
                      onClick={BijlageModal}
                    >
                      Voeg extra bijlage toe
                    </button>
                    {extraBijlagen.length > 0 ? (
                      <div
                        style={{
                          width: "98%",
                          marginLeft: "8px",
                        }}
                      >
                        <Select
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: state.hasValue ? "47.5px" : "47.5px",
                            }),
                          }}
                          options={extraBijlagen}
                          value={selectedExtraBijlagen}
                          isMulti
                          isClearable={true}
                          closeMenuOnSelect={false}
                          onChange={(s) => {
                            setSelectedExtraBijlagen(s);
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "98%",
                          marginLeft: "8px",
                        }}
                      >
                        <Select
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              height: state.hasValue ? "47.5px" : "47.5px",
                            }),
                          }}
                          isDisabled
                        />
                      </div>
                    )}
                  </div>
                  <br />
                  <label className="label-inspectie">Zichtbaar maken</label>
                  <Select
                    isMulti
                    IsClearable={false}
                    options={zichtbaarMaken}
                    onChange={(e) => {
                      setInstellingen(e.map((object) => object.value));
                    }}
                  />
                </div>
                <div className="rapportage-input-col">
                  <label className="label-inspectie">Subtitel</label>
                  <br />
                  <textarea
                    placeholder="Type here"
                    spellCheck={false}
                    style={{
                      width: "70vw",
                      height: "30vh",
                    }}
                    className="rapportage-textarea"
                    value={subtitel}
                    onChange={(e) => setSubtitel(e.target.value)}
                  />
                </div>
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">1. Inleiding</h1>
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">
                  Inleiding algemeen <Tekst toParent={setInleidingAlgemeen} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={inleidingAlgemeen}
                  onChange={(e) => setInleidingAlgemeen(e.target.value)}
                />
                <label className="label-inspectie">
                  Kwaliteitswaarborg{" "}
                  <Tekst toParent={setInleidingKwaliteitswaarborg} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={inleidingKwaliteitswaarborg}
                  onChange={(e) =>
                    setInleidingKwaliteitswaarborg(e.target.value)
                  }
                />
                <label className="label-inspectie">
                  Herstelgebreken{" "}
                  <Tekst toParent={setInleidingHerstelgebreken} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={inleidingHerstelgebreken}
                  onChange={(e) => setInleidingHerstelgebreken(e.target.value)}
                />
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">2. Algemene gegevens</h1>
              </div>
              <div className="rapportage-input-col">
                <div className="rapportage-input-left">
                  <label className="label-inspectie">Zichtbaar maken</label>
                  <br />
                  <Select
                    isMulti
                    isClearable={true}
                    options={zichtbaarMaken2}
                    onChange={(e) =>
                      setInstallatieverantwoordelijke(e.map((object) => object))
                    }
                  />
                  <br />
                  <label className="label-inspectie">Meetinstrumenten</label>
                  <Instruments
                    defaults={meetinstrumenten}
                    toParent={setMeetinstrumenten}
                  />
                </div>
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">3. Kenmerken</h1>
              </div>
              <div className="rapportage-input-col">
                <Aansluitgegevens
                  defaults={aansluitgegevensArr}
                  toParent={setAansluitgegevenid}
                  aansluitgegevens={aansluitgegevensArr}
                  defaults2={objectfuncties}
                  toParent2={setObjectfuncties}
                  setArray={setAansluitgegevensArr}
                />
                <label className="label-inspectie">
                  Kenmerken van technische installatie{" "}
                  <Tekst toParent={setKenmerkalgemeen} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={kenmerkAlgemeen}
                  onChange={(e) => setKenmerkalgemeen(e.target.value)}
                />
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">4. Inspectie</h1>
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">
                  Omvang inspectie <Tekst toParent={setOmvanginspectie} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={omvanginspectie}
                  onChange={(e) => setOmvanginspectie(e.target.value)}
                />
                <label className="label-inspectie">Niet geïnspecteerd:</label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={nietgeinspecteerd}
                  onChange={(e) => setNietgeinspecteerd(e.target.value)}
                />
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">
                  4.2 Uitgangspunten inspectie
                </h1>
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">
                  Reden van inspectie: <Tekst toParent={setRedeninspectie} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={redeninspectie}
                  onChange={(e) => setRedeninspectie(e.target.value)}
                />
                <label className="label-inspectie">
                  Inspectie uitgevoerd volgens:
                </label>
                <Normen
                  defaults={uitgangspuntenArr}
                  toParent={setNormid}
                  setArray={setUitgangspuntenArr}
                />
                <label className="label-inspectie">
                  De Installatie is getoetst aan:
                </label>
                <NormenGetoetst
                  defaults={uitgangspuntenArr2}
                  toParent={setNormid2}
                  setArray={setUitgangspuntenArr2}
                />
                <label className="label-inspectie">
                  Beoordelingscriteria:{" "}
                  <Tekst toParent={setBeoordelingscriteria} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={beoordelingscriteria}
                  onChange={(e) => setBeoordelingscriteria(e.target.value)}
                />
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">4.3 Inspectie methode</h1>
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">
                  Controle punten:{" "}
                  <Tekst toParent={setBedrijfsomstandigheden} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={bedrijfsomstandigheden}
                  onChange={(e) => setBedrijfsomstandigheden(e.target.value)}
                />
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">5. Volgende inspectie</h1>
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">
                  Volgende inspectie <Tekst toParent={setVolgendeInspectie} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={volgendeinspectie}
                  onChange={(e) => setVolgendeInspectie(e.target.value)}
                />
                <label className="label-inspectie">Installatie leeftijd</label>
                <Select
                  options={installatieLeeftijd}
                  onChange={async (e) => {
                    setLeeftijd(e.value);

                    let object_values = {
                      leeftijd: e.value.toString(),
                      kwaliteit: kwaliteit,
                      omgeving: omgevingsomstandigheden,
                      personen: personen,
                      matevantoezicht: matevantoezicht,
                    };

                    calculatePunten(object_values);
                  }}
                />
                <br />
                <label className="label-inspectie">Installatie kwaliteit</label>
                <Select
                  options={installatieKwaliteit}
                  onChange={(e) => {
                    setKwaliteit(e.value);
                    let object_values = {
                      leeftijd: leeftijd,
                      kwaliteit: e.value.toString(),
                      omgeving: omgevingsomstandigheden,
                      personen: personen,
                      matevantoezicht: matevantoezicht,
                    };

                    calculatePunten(object_values);
                  }}
                />
                <br />
                <label className="label-inspectie">
                  Omgevingsomstandigheden
                </label>
                <Select
                  options={omgevingsOmstandigheden}
                  onChange={(e) => {
                    setOmgevingsomstandigheden(e.value);
                    let object_values = {
                      leeftijd: leeftijd,
                      kwaliteit: kwaliteit,
                      omgeving: e.value.toString(),
                      personen: personen,
                      matevantoezicht: matevantoezicht,
                    };

                    calculatePunten(object_values);
                  }}
                />
                <br />
                <label className="label-inspectie">
                  Personen die installatie gebruiken
                </label>
                <Select
                  options={installatiePersonen}
                  onChange={(e) => {
                    setPersonen(e.value);
                    let object_values = {
                      leeftijd: leeftijd,
                      kwaliteit: kwaliteit,
                      omgeving: omgevingsomstandigheden,
                      personen: e.value.toString(),
                      matevantoezicht: matevantoezicht,
                    };

                    calculatePunten(object_values);
                  }}
                />
                <br />
                <label className="label-inspectie">Mate van toezicht</label>
                <Select
                  options={regelmaat}
                  onChange={(e) => {
                    setMatevantoezicht(e.value);
                    let object_values = {
                      leeftijd: leeftijd,
                      kwaliteit: kwaliteit,
                      omgeving: omgevingsomstandigheden,
                      personen: personen,
                      matevantoezicht: e.value.toString(),
                    };

                    calculatePunten(object_values);
                  }}
                />
                <br />
                <label style={{ marginTop: "2vw" }}>
                  Punten totaal: {totaalPunten}
                </label>
                <br />
                <label style={{ marginTop: "1.5vw" }}>
                  Volgende afspraak:{" "}
                </label>
                <DateTime
                  locale="nl"
                  timeFormat={false}
                  dateFormat="DD MMMM Y"
                  initialValue={new Date()}
                  onChange={(e) => {
                    const date = new Date(e).toLocaleDateString("nl", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    });
                    setDatum(date);
                  }}
                />
              </div>
              <hr className="hr1" />
              <div className="selected-inspectie-row-title">
                <h1 className="inspectie-header">6. Eindbeoordeling</h1>
              </div>
              <div className="rapportage-input-col">
                <label className="label-inspectie">
                  Eindbeoordeling <Tekst toParent={setBeoordeling} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea "
                  value={beoordeling}
                  onChange={(e) => setBeoordeling(e.target.value)}
                />
                <label className="label-inspectie">
                  Toelichting algemeen / aanbevelingen{" "}
                  <Tekst toParent={setToelichting} />
                </label>
                <textarea
                  placeholder="Type here"
                  spellCheck={false}
                  style={{ width: "70vw", height: "30vh" }}
                  className="rapportage-textarea"
                  value={toelichting}
                  onChange={(e) => setToelichting(e.target.value)}
                />
              </div>
              <hr className="hr1" />
              <div style={{ height: "100px" }}>
                <br />

                <button
                  style={{ marginBottom: 10 }}
                  className="submit-rapportage"
                  onClick={insertReport}
                >
                  Aanmaken
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Inspectie;
