import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Select from "react-select";

import "../../scss/customers/select-customer.scss";

//Navigation
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";
import { get_all_customers } from "../../functions/api";

// <button className="button-delete" onClick={() => {deleteReport(object.rapportid)}}>DELETE</button>

function Customers() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  let [search, setSearch] = useState("");
  let [sort, setSort] = useState();
  const filtered = !search
    ? data
    : data.filter((tekst) => {
        return (
          tekst.klantnaam.toLowerCase().includes(search.toLowerCase()) ||
          tekst.bedrijfsnaam.includes(search) ||
          tekst.email.toLowerCase().includes(search.toLocaleLowerCase()) ||
          tekst.website.includes(search.toLocaleLowerCase())
        );
      });

  const options = [
    { value: 0, label: "Standaard" },
    { value: 1, label: "Alfabetische volgorde A-Z (klantnaam)" },
    { value: 2, label: "Alfabetische volgorde Z-A (klantnaam)" },
    { value: 3, label: "Alfabetische volgorde A-Z (bedrijfsnaam)" },
    { value: 4, label: "Alfabetische volgorde Z-A (bedrijfsnaam)" },
    { value: 5, label: "Alfabetische volgorde A-Z (email)" },
    { value: 6, label: "Alfabetische volgorde Z-A (email)" },
  ];

  const sorted = () => {
    switch (sort) {
      case 1:
        data.sort((a, b) =>
          a.klantnaam.toLocaleLowerCase() < b.klantnaam.toLocaleLowerCase()
            ? -1
            : 1
        );
        break;
      case 2:
        data.sort((a, b) =>
          a.klantnaam.toLocaleLowerCase() > b.klantnaam.toLocaleLowerCase()
            ? -1
            : 1
        );
        break;
      case 3:
        data.sort((a, b) =>
          a.bedrijfsnaam.toLocaleLowerCase() <
          b.bedrijfsnaam.toLocaleLowerCase()
            ? -1
            : 1
        );
        break;
      case 4:
        data.sort((a, b) =>
          a.bedrijfsnaam.toLocaleLowerCase() >
          b.bedrijfsnaam.toLocaleLowerCase()
            ? -1
            : 1
        );
        break;
      case 5:
        data.sort((a, b) =>
          a.email.toLocaleLowerCase() < b.email.toLocaleLowerCase() ? -1 : 1
        );
        break;
      case 6:
        data.sort((a, b) =>
          a.email.toLocaleLowerCase() > b.email.toLocaleLowerCase() ? -1 : 1
        );
        break;
      default:
        data.sort((a, b) => b.id - a.id);
        break;
    }
    return;
  };
  useEffect(() => {
    get_all_customers()
      .then((customer) => {
        if (customer.ok) {
          return customer.json();
        }
        throw customer;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(false);
  }, []);

  if (loading === true) {
    return (
      <div className="select-customer-container-main">
        <Nav page="klanten" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div
            className="select-customer-container"
            style={{ marginTop: "30px" }}
          >
            <h1 className="customer-title">Klanten</h1>
            <div className="select-customer-info">
              <div className="select-customer-info-left">
                <input
                  className="select-customer-search"
                  placeholder="  Zoeken .."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="select-customer-info-right">
                <Select
                  isSearchable={false}
                  defaultValue={options[0]}
                  options={options}
                  onChange={(e) => setSort(e.value)}
                />
                <Link to="/customers/create" className="select-customer-create">
                  <p>Aanmaken</p>
                </Link>
              </div>
            </div>
            <div className="select-customer-container-1">
              <div className="select-customer-column-names">
                <p>Naam</p>
                <p>Bedrijfsnaam</p>
                <p>Telefoon</p>
                <p>Email</p>
                <p>Website</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (loading === false) {
    return (
      <div className="select-customer-container-main">
        <Nav page="klanten" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="select-customer-container">
            <h1 className="customer-title">Klanten</h1>
            <div className="select-customer-info">
              <div className="select-customer-info-left">
                <input
                  className="select-customer-search"
                  placeholder="  Zoeken.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="select-customer-info-right">
                <Select
                  isSearchable={false}
                  defaultValue={options[0]}
                  options={options}
                  onChange={(e) => setSort(e.value)}
                />
                <Link to="/customers/create" className="select-customer-create">
                  <p>Aanmaken</p>
                </Link>
              </div>
            </div>
            <div className="select-customer-container-1">
              <div className="table-responsive">
                <Table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Naam</th>
                      <th scope="col">Bedrijfsnaam</th>
                      <th scope="col">Telefoon</th>
                      <th scope="col">Email</th>
                      <th scope="col">Website</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      borderRadius: "5px",
                      boxShadow:
                        "rgba($color: #060a24, $alpha: 0.05) 0px 0px 0px 1px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    {sorted()}
                    {filtered.map((object) => (
                      <tr
                        key={object.id}
                        className="select-customer-row select-customer-object"
                        onClick={() => {
                          navigate("/customers/edit_customer", {
                            state: { customer: object },
                          });
                        }}
                      >
                        <td>{object.klantnaam}</td>
                        <td>{object.bedrijfsnaam}</td>
                        <td>{object.telefoon}</td>
                        <td>{object.email}</td>
                        <td> {object.website}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Customers;
