import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Select from "react-select";
import Swal from "sweetalert2";

import { FaCircle } from "react-icons/fa";
import "../../scss/reports/select-report.scss";
// Get Reports
import { get_all_rapporten, delete_rapportage } from "../../functions/api";
//Navigation
import Menu from "../navigation/Menu";
import Nav from "../navigation/Nav";
import { Table } from "react-bootstrap";

// permissions
const permissions = JSON.parse(localStorage.getItem("permissions"));

function Reports() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  let [search, setSearch] = useState("");
  let [sort, setSort] = useState();
  const filtered = !search
    ? data.rapportage_object
    : data.rapportage_object.filter((tekst) => {
        return (
          tekst.rapport_info.klantnaam
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          tekst.rapportid.includes(search) ||
          tekst.rapport_info.objectnaam
            .toLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          tekst.rapport_info.datumstart.includes(search)
        );
      });

  const options = [
    { value: 0, label: "Standaard" },
    { value: 1, label: "Alfabetische volgorde A-Z (klantnaam)" },
    { value: 2, label: "Alfabetische volgorde Z-A (klantnaam)" },
    { value: 3, label: "Klein naar groot (datum inspectie)" },
    { value: 4, label: "groot naar klein (datum inspectie)" },
  ];

  const sorted = () => {
    switch (sort) {
      case 1:
        data.rapportage_object.sort((a, b) =>
          a.rapport_info.klantnaam.toLocaleLowerCase() <
          b.rapport_info.klantnaam.toLocaleLowerCase()
            ? -1
            : 1
        );
        break;
      case 2:
        data.rapportage_object.sort((a, b) =>
          a.rapport_info.klantnaam.toLocaleLowerCase() >
          b.rapport_info.klantnaam.toLocaleLowerCase()
            ? -1
            : 1
        );
        break;
      case 3:
        data.rapportage_object.sort((a, b) =>
          a.rapport_info.datumstart < b.rapport_info.datumstart ? -1 : 1
        );
        break;
      case 4:
        data.rapportage_object.sort((a, b) =>
          a.rapport_info.datumstart > b.rapport_info.datumstart ? -1 : 1
        );
        break;
      default:
        data.rapportage_object.sort((a, b) => b.rapportid - a.rapportid);
        break;
    }
    return;
  };
  useEffect(() => {
    get_all_rapporten()
      .then((report) => {
        if (report.ok) {
          return report.json();
        }
        throw report;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
        if (permissions.viewPermissions.showRapportages === "0") {
          Swal.fire({
            title: "Geen toegang!",
            text: "U wordt terug gestuurd naar de vorige pagina.",
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            navigate(-1);
          });
        }
      });
  }, []);
  if (loading === true) {
    return (
      <div className="select-report-container-main">
        <Nav page="Rapportages" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div
            className="select-report-container"
            style={{ marginTop: "30px" }}
          >
            <h1 className="report-title">Rapportages</h1>
            <div className="select-report-info">
              <div className="select-report-info-left">
                <input
                  className="select-report-search"
                  placeholder="  Zoeken .."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="select-report-info-right">
                <Select
                  isSearchable={false}
                  defaultValue={options[0]}
                  options={options}
                  onChange={(e) => setSort(e.value)}
                />
                <Link
                  to="/reports/select_inspection"
                  className="select-report-create"
                >
                  <p>Aanmaken</p>
                </Link>
              </div>
            </div>
            <div className="select-report-container-1">
              {/* <div className="select-report-column-names">
                                <p>Rapport nr.</p>
                                <p>Klantnaam</p>
                                <p>Objectnaam</p>
                                <p>Datum inspectie</p>
                                <p>Status</p>
                            </div> */}
              <div className="table-responsive">
                <Table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rapport nr.</th>
                      <th scope="col">Klantnaam</th>
                      <th scope="col">Objectnaam</th>
                      <th scope="col">Datum inspectie</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      borderRadius: "5px",
                      boxShadow:
                        "rgba($color: #060a24, $alpha: 0.05) 0px 0px 0px 1px",
                      backgroundColor: "#ffffff",
                    }}
                  ></tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (loading === false) {
    return (
      <div className="select-report-container-main">
        <Nav page="Rapportages" />
        <div className="page-wrap-row">
          <Menu className="menu-container" />
          <div className="select-report-container">
            <h1 className="font-medium text-3xl mt-12 ml-5 lg:ml-8 pb-2">
              Rapportages
            </h1>
            <div className="select-report-info">
              <div className="select-report-info-left">
                <input
                  className="select-report-search"
                  placeholder="  Zoeken.."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div className="select-report-info-right">
                <Select
                  isSearchable={false}
                  defaultValue={options[0]}
                  options={options}
                  onChange={(e) => setSort(e.value)}
                />
                <Link
                  to="/reports/select_inspection"
                  className="select-report-create"
                >
                  <p>Aanmaken</p>
                </Link>
              </div>
            </div>
            <div className="select-report-container-1">
              <div className="table-responsive">
                <Table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rapport nr.</th>
                      <th scope="col">Klantnaam</th>
                      <th scope="col">Objectnaam</th>
                      <th scope="col">Datum inspectie</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      borderRadius: "5px",
                      boxShadow:
                        "rgba($color: #060a24, $alpha: 0.05) 0px 0px 0px 1px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    {sorted()}
                    {filtered.map((object) => (
                      <tr
                        key={object.rapportid}
                        className="select-report-row select-report-object"
                        onClick={() => {
                          navigate("/reports/edit_report", {
                            state: { rapportId: object.rapportid },
                          });
                        }}
                      >
                        <td>{object.rapportid}</td>
                        <td>{object.rapport_info.klantnaam}</td>
                        <td>{object.rapport_info.objectnaam}</td>
                        <td>
                          <Moment locale="nl" format="DD-MMM YYYY">
                            {object.rapport_info.datumstart}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Reports;
