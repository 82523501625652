import React from "react";
import Select from "react-select";

function InhoudsOpgave({ defaults, toParent, edit }) {
  let inhoudsOpgave = [];
  if (edit === true) {
    inhoudsOpgave = [
      { value: 1, label: "Gebreken" },
      { value: 2, label: "Advies" },
      { value: 3, label: "Thermografisch" },
      { value: 4, label: "Herstelwerkzaamheden" },
      { value: 5, label: "Herstelverklaring" },
      { value: 6, label: "Inspectieplan" },
      { value: 7, label: "Meetresultaten" },
      { value: 8, label: "Eindbeoordeling" },
    ];
  } else {
    inhoudsOpgave = [
      { value: 1, label: "Gebreken" },
      { value: 2, label: "Advies" },
      { value: 4, label: "Herstelwerkzaamheden" },
      { value: 5, label: "Herstelverklaring" },
      { value: 6, label: "Inspectieplan" },
      { value: 7, label: "Meetresultaten" },
      { value: 8, label: "Eindbeoordeling" },
    ];
  }

  let inhoudsopgave = [];
  let data = defaults;
  if (defaults !== undefined) {
    inhoudsopgave = data.map((object) => {
      let selectedOptions = inhoudsOpgave.filter(
        (obj_options) => obj_options.label == object
      );
      return {
        value: selectedOptions[0].value,
        label: selectedOptions[0].label,
      };
    });
  }

  return (
    <Select
      defaultValue={inhoudsopgave}
      options={inhoudsOpgave}
      onChange={(e) => toParent(e.map((object) => object.label))}
      isMulti
      isClearable={false}
      closeMenuOnSelect={false}
    />
  );
}

export default InhoudsOpgave;
